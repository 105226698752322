import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';

@Injectable()
export class ServerFunctionsService {
  constructor(
    private _http: CoolHttp,
  ) {}

  public async clearCacheAsync() {
    await this._http.deleteAsync('api/backoffice/server-functions/global-cache');
  }

  public async ensureTrackingEventsPartitionedTablesAsync() {
    await this._http.postAsync('api/backoffice/server-functions/ensure-tracking-events-partitioned-tables');
  }

  public async ensureVisitsPartitionedTablesAsync() {
    await this._http.postAsync('api/backoffice/server-functions/ensure-visits-partitioned-tables');
  }

  public async ensurePageViewsPartitionedTablesAsync() {
    await this._http.postAsync('api/backoffice/server-functions/ensure-page-views-partitioned-tables');
  }

  public async reIndexProductsAsync() {
    await this._http.postAsync('api/backoffice/server-functions/re-index/products');
  }

  public async reIndexBrandsAsync() {
    await this._http.postAsync('api/backoffice/server-functions/re-index/brands');
  }
}
