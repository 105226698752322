<ng-container *ngIf="allAccounts">
  <mat-form-field>
    <mat-label>Account</mat-label>
    <mat-select [name]="name" [(ngModel)]="selectedAccount"
                (ngModelChange)="onAccountSelectionChanged($event)" [compareWith]="compareAccounts">
      <mat-option *ngFor="let account of allAccounts;" [value]="account">
        <app-brand-by-account-tag [accountId]="account.id" [disableClick]="true"></app-brand-by-account-tag>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
