import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import { BackofficeAppState, BackofficeAppStateModel } from '../../app.state';
import { ENVIRONMENT } from '../utils/injection-tokens';
import { Environment } from '../../../environments/environment.interface';
import { UserSession } from '../../../../../common/dto/user-session.dto';

@Injectable()
export class LoggerService {
  constructor(private _store: Store, @Inject(ENVIRONMENT) private _environment: Environment) {
    this._store
      .select<BackofficeAppStateModel>(BackofficeAppState)
      .pipe(filter((state) => !!state?.isInitialized))
      .subscribe((state) => {
        this._onUserLoggedIn(state.session);
      });
  }

  public initialize() {
    if (this._environment.logging?.enabled) {
      Sentry.init({
        dsn: this._environment.logging.dsn,
        autoSessionTracking: true,
        tracesSampleRate: 1,
      });
    }
  }

  public error(error: Error, data?: any) {
    console.error(error, data);

    if (data) {
      this.captureBreadcrumb('error-data', data);
    }

    if ((<any>error).data) {
      try {
        this.captureBreadcrumb(error.message, JSON.stringify((<any>error).data));
      } catch {
        console.log('Failed to capture breadcrumb');
      }
    }

    if ((<any>error).body) {
      try {
        this.captureBreadcrumb(error.message, JSON.stringify((<any>error).body));
      } catch {
        console.log('Failed to capture breadcrumb');
      }
    }

    Sentry.captureException(error);
  }

  public captureBreadcrumb(message: string, data: any = null) {
    Sentry.addBreadcrumb({
      message,
      data,
    });
  }

  private _onUserLoggedIn(user: UserSession | undefined) {
    Sentry.setContext('user', {
      id: user?.user.userId,
    });
  }
}
