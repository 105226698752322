import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { PartnershipDTO } from '../../../../../common/dto/partnership.dto';
import { PCacheable } from 'ts-cacheable';
import { MINUTE_IN_MILLISECONDS } from '../../../../../common/utils/date.utils';
import { PartnershipRequestDTO } from '../../../../../common/dto/partnership-request.dto';
import { AccountId } from '../../../../../common/dto/account.dto';

@Injectable()
export class PartnershipsService {
  constructor(
    private _http: CoolHttp,
  ) {}

  public async getPartnersOfAccountAsync(accountId: AccountId): Promise<PartnershipDTO[]> {
    return await this._http.getAsync(`api/partnerships/${ accountId }`);
  }

  @PCacheable({
    maxAge: 5 * MINUTE_IN_MILLISECONDS,
  })
  public async getBrandPartnershipRequestsAsync(): Promise<PartnershipRequestDTO[]> {
    return await this._http.getAsync('api/partnership-requests');
  }
}
