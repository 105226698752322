import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { AccountsService } from '../../services/accounts.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AccountDTO, AccountId } from '../../../../../../common/dto/account.dto';

@UntilDestroy()
@Component({
  selector: 'app-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountSelectorComponent),
      multi: true,
    },
  ],
})
export class AccountSelectorComponent implements OnInit, ControlValueAccessor {
  private _onChange?: (account: AccountDTO | undefined) => void;
  private _onTouched?: (account: AccountDTO | undefined) => void;

  constructor(
    private _accountsService: AccountsService,
    private _changeDetector: ChangeDetectorRef,
  ) { }

  @Input()
  public name: string = 'account-selector';

  public allAccounts?: AccountDTO[];

  public selectedAccount: AccountDTO | undefined;

  public async ngOnInit() {
    this.allAccounts = await this._accountsService.getAccountsAsync(<AccountId>'99999999999', undefined);

    this._changeDetector.markForCheck();
  }

  public onAccountSelectionChanged(account: AccountDTO | undefined) {
    this.selectedAccount = account;

    this._onTouched?.call(this, this.selectedAccount);
    this._onChange?.call(this, this.selectedAccount);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public writeValue(selectedAccount: AccountDTO | undefined): void {
    this.selectedAccount = selectedAccount;

    this._changeDetector.markForCheck();
  }

  public compareAccounts(accountA: AccountDTO | undefined, accountB: AccountDTO | undefined): boolean {
    return accountA?.id === accountB?.id;
  }
}
