import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { TrackingCartId, VisitorDebuggerDTO } from '../../../../../common/dto/visitor-debugger.dto';
import { DateUtils } from '../../../../../common/utils/date.utils';
import { PageViewDTO, VisitId } from '../../../../../common/dto/visit.dto';
import { VisitorDeviceId } from '../../../../../common/dto/visitor.dto';

@Injectable()
export class VisitorDebuggerService {
  constructor(private _http: CoolHttp) {}

  public async getVisitorDebugDataAsync(
    visitorDeviceIdOrVisitorId: string,
    start: Date,
    end: Date,
  ): Promise<VisitorDebuggerDTO> {
    return await this._http.getAsync('api/backoffice/visitor-debugger', {
      params: {
        id: visitorDeviceIdOrVisitorId,
        start: DateUtils.endOfDay(start).toISOString(),
        end: DateUtils.endOfDay(end).toISOString(),
      },
    });
  }

  public async getPageViewsInVisitAsync(visitId: VisitId, end: Date, limit = 100): Promise<PageViewDTO[]> {
    return await this._http.getAsync(`api/visits/${ visitId }/page-views`, {
      params: {
        end: end.toISOString(),
        limit: limit.toString(),
      },
    });
  }

  public async markCartAsNotReferredAsync(cartId: TrackingCartId) {
    await this._http.deleteAsync(`api/tracking-carts/${ cartId }/referrer`);
  }

  public async removeDevicesFromVisitorAsync(deviceIds: VisitorDeviceId[]) {
    await this._http.postAsync(`api/visitors/devices/visitor/remove`, {
      deviceIds: deviceIds,
    });
  }
}
