import { Inject, Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { AudienceExportDTO, AudienceExportId } from '../../../../../common/dto/audiences.dto';
import { DateUtils } from '../../../../../common/utils/date.utils';
import { WINDOW } from '../utils/injection-tokens';

@Injectable()
export class AudienceExportsService {
  constructor(
    private _http: CoolHttp,
    @Inject(WINDOW) private _window: Window,
  ) {}

  public async getAudienceExportsAsync(startDate: Date, endDate: Date): Promise<AudienceExportDTO[]> {
    return await this._http.getAsync('api/audience-exports', {
      params: {
        start: DateUtils.endOfDay(startDate).toISOString(),
        end: DateUtils.endOfDay(endDate).toISOString(),
      },
    });
  }

  public async downloadAudienceExportAsync(audienceExportId: AudienceExportId) {
    const downloadUrl = await this._http.postAsync<{
      url: string;
    }>(`api/audience-exports/${ audienceExportId }/download`);

    this._window.open(downloadUrl.url, '_blank');
  }
}
