import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,

  serverUrl: 'https://api.joinohana.io',

  loginUrl: 'https://login.joinohana.io',

  visitorGlobalUrl: 'https://visitor.joinohana.io',

  logging: {
    enabled: true,

    dsn: 'https://39b3f5a234c943eeb1c41d0150e71903@o1412514.ingest.sentry.io/6752411',
  },
};
