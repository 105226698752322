import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CoolHttpModule } from '@angular-cool/http';
import { CoolStorageModule } from '@angular-cool/storage';
import { environment } from '../../environments/environment';
import { LoggerService } from './logger/logger.service';
import { AuthenticationService } from './services/authentication.service';
import { UnauthenticatedInterceptor } from './startup/unauthenticated.interceptor';
import { BackofficeRouteNavigationService } from './routing/route-navigation.service';
import { ENVIRONMENT, WINDOW } from './utils/injection-tokens';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { CoolLoadingButtonModule } from '@angular-cool/loading-button';
import { MatInputModule } from '@angular/material/input';
import { LoggedInRouteGuardLogic } from './routing/route-guards/logged-in.guard';
import { FeaturePermissionsRouteGuardLogic } from './routing/route-guards/feature-permissions.guard';
import { StartupService, startupServiceFactory } from './startup/startup.service';
import { GlobalErrorHandler } from './logger/global-error-handler';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CoolDialogsModule } from '@angular-cool/dialogs';
import { MatSelectModule } from '@angular/material/select';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import {
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faExclamationCircle,
  faExclamationTriangle,
  faSearch,
  faTimes,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { FileDisplayComponent } from './components/file-display/file-display.component';
import { FeaturePermissionEnabledDirective } from './directives/feature-permission-enabled.directive';
import { AccountsService } from './services/accounts.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { BrandsService } from './services/brands.service';
import { ServerFunctionsService } from './services/server-functions.service';
import { PartnershipsService } from './services/partnerships.service';
import { BrandTagComponent } from './components/brand-tag/brand-tag.component';
import { BrandByAccountTagComponent } from './components/brand-by-account-tag/brand-by-account-tag.component';
import { VisitorDevicesService } from './services/visitor-devices.service';
import { AsPipe } from './pipes/as.pipe';
import { AccountSelectorComponent } from './components/account-selector/account-selector.component';
import { FeatureOverridesService } from './services/feature-overrides.service';
import { VisitorDebuggerService } from './services/visitor-debugger.service';
import { PlacementsService } from './services/placements.service';
import { DropshippingService } from './services/dropshipping.service';
import { AudienceExportsService } from './services/audience-exports.service';
import { RedirectAfterService } from './services/redirect-after.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,

    FontAwesomeModule,

    CoolLoadingButtonModule,

    CoolHttpModule,
    CoolStorageModule,
    CoolDialogsModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,

    FontAwesomeModule,

    CoolLoadingButtonModule,

    CoolHttpModule,
    CoolStorageModule,
    CoolDialogsModule,

    FileUploaderComponent,
    FileDisplayComponent,

    FeaturePermissionEnabledDirective,
    BrandTagComponent,
    BrandByAccountTagComponent,

    AccountSelectorComponent,

    AsPipe,
  ],
  declarations: [
    FileUploaderComponent,
    FileDisplayComponent,
    FeaturePermissionEnabledDirective,
    BrandTagComponent,
    BrandByAccountTagComponent,

    AccountSelectorComponent,

    AsPipe,
  ],
})
export class AppCommonModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faTimesCircle,
      faCheckCircle,
      faTrash,
      faChevronUp,
      faChevronDown,
      faExclamationTriangle,
      faExclamationCircle,
      faSearch,
      faTimes,
    );
  }

  public static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [
        LoggerService,
        AuthenticationService,
        UnauthenticatedInterceptor,
        BackofficeRouteNavigationService,
        StartupService,
        AccountsService,
        BrandsService,
        ServerFunctionsService,
        PartnershipsService,
        VisitorDevicesService,
        FeatureOverridesService,
        VisitorDebuggerService,
        PlacementsService,
        DropshippingService,
        AudienceExportsService,
        RedirectAfterService,

        LoggedInRouteGuardLogic,
        FeaturePermissionsRouteGuardLogic,

        ...(<Provider[]>CoolHttpModule.forRoot().providers),
        ...(<Provider[]>CoolStorageModule.forRoot().providers),
        ...(<Provider[]>CoolDialogsModule.forRoot().providers),

        { provide: ENVIRONMENT, useValue: environment },
        { provide: WINDOW, useValue: window },

        {
          provide: ErrorHandler,
          useClass: environment.logging?.enabled ? GlobalErrorHandler : ErrorHandler,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: startupServiceFactory,
          deps: [StartupService],
          multi: true,
        },
      ],
    };
  }
}
