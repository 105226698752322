import { Inject, Injectable } from '@angular/core';
import { Environment } from '../../../environments/environment.interface';
import { CoolHttp } from '@angular-cool/http';
import { LoggerService } from '../logger/logger.service';
import { UnauthenticatedInterceptor } from './unauthenticated.interceptor';
import { AuthenticationService } from '../services/authentication.service';
import { Store } from '@ngxs/store';
import { SetIsInitializedAction } from '../../app.state';
import { ENVIRONMENT } from '../utils/injection-tokens';

export function startupServiceFactory(provider: StartupService) {
  return () => provider.setupAsync();
}

@Injectable()
export class StartupService {
  constructor(
    private _store: Store,
    private _http: CoolHttp,
    private _loggerService: LoggerService,
    private _authenticationService: AuthenticationService,
    private _unauthenticatedInterceptor: UnauthenticatedInterceptor,
    @Inject(ENVIRONMENT) private _environment: Environment,
  ) {}

  public async setupAsync() {
    this._loggerService.initialize();

    this._setupHttp();

    await this._authenticationService.initializeAsync();

    await this._store.dispatch(new SetIsInitializedAction()).toPromise();
  }

  private _setupHttp() {
    this._http.registerBaseUrl(this._environment.serverUrl);

    this._http.registerResponseInterceptor(this._unauthenticatedInterceptor);
  }
}
