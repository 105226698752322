<div class="flex-row-center"
     [class.cursor-pointer]="!disableClick"
     style="max-width: 250px"
     (click)="onClickAsync();">
  <app-file-display *ngIf="brand.logo"
                    [file]="brand.logo"
                    height="14px" width="30px">
  </app-file-display>

  <div class="ms-2 text-truncate flex-grow-1">{{ brand.name }}</div>
</div>
