import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BackofficeRouteLocations } from './route-locations';
import { AccountId } from '../../../../../common/dto/account.dto';
import { RedirectAfterService } from '../services/redirect-after.service';
import { MonitoredWebpageId, MonitoredWebpageReportId } from '../../../../../common/dto/monitoring.dto';

@Injectable()
export class BackofficeRouteNavigationService {

  constructor(
    private _router: Router,
    private _redirectAfterService: RedirectAfterService,
  ) {}

  public async goToLoginAsync(redirectToCurrentUrlAfterLogin: boolean = false) {
    if (redirectToCurrentUrlAfterLogin) {
      this._redirectAfterService.saveCurrentUrlForRedirect();
    }

    await this._router.navigateByUrl(BackofficeRouteLocations.Login.absoluteUrl);
  }

  public async goToRegistrationAsync(redirectToCurrentUrlAfterLogin: boolean = false) {
    if (redirectToCurrentUrlAfterLogin) {
      this._redirectAfterService.saveCurrentUrlForRedirect();
    }

    await this._router.navigateByUrl(BackofficeRouteLocations.Login.getAbsoluteUrlWithQuery({
      register: '1',
    }));
  }


  public async goToDashboardAsync() {
    await this._router.navigateByUrl(BackofficeRouteLocations.Dashboard);
  }

  public async goToNotFoundAsync() {
    await this._router.navigateByUrl(
      this._buildUrl([
        BackofficeRouteLocations.Dashboard,
        BackofficeRouteLocations.DashboardChildren.NotFound,
      ]),
    );
  }

  public async goToAccountDetailsAsync(accountId: AccountId) {
    await this._router.navigateByUrl(
      this._buildUrl([
        BackofficeRouteLocations.Dashboard,
        BackofficeRouteLocations.DashboardChildren.Accounts,
        BackofficeRouteLocations.DashboardChildren.AccountsChildren.AccountDetails.replace(':accountId', accountId),
      ]),
    );
  }

  public async goToMonitoringReportListAsync(webpageId: MonitoredWebpageId) {
    await this._router.navigateByUrl(
      this._buildUrl([
        BackofficeRouteLocations.Dashboard,
        BackofficeRouteLocations.DashboardChildren.Monitoring,
        BackofficeRouteLocations.DashboardChildren.MonitoringChildren.ReportList.replace(':webpageId', webpageId),
      ]),
    );
  }

  public async goToMonitoringReportDetailsAsync(webpageId: MonitoredWebpageId, reportId: MonitoredWebpageReportId) {
    await this._router.navigateByUrl(
      this._buildUrl([
        BackofficeRouteLocations.Dashboard,
        BackofficeRouteLocations.DashboardChildren.Monitoring,
        BackofficeRouteLocations.DashboardChildren.MonitoringChildren.Report.replace(':webpageId', webpageId).replace(':reportId', reportId),
      ]),
    );
  }

  public getAccountListUrl(): string {
    return this._buildUrl([
      BackofficeRouteLocations.Dashboard,
      BackofficeRouteLocations.DashboardChildren.Accounts,
      BackofficeRouteLocations.DashboardChildren.AccountsChildren.AccountList,
    ]);
  }

  private _buildUrl(parts: string[], queryParams?: { [name: string]: string; }): string {
    let url = '/' + parts.filter(_ => !!_).join('/');

    const queryParamsList = Object.entries(queryParams || {});

    if (queryParamsList.length) {
      url = url + '?' + queryParamsList.map(_ => `${ _[0] }=${ encodeURIComponent(_[1]) }`).join('&');
    }

    return url;
  }

  public async changeToAccountAsync(accountId: AccountId) {
    await this._router.navigateByUrl(this._router.url.replace(/\d+/, accountId));
  }

  public async replaceQueryParamsAsync(activatedRoute: ActivatedRoute, queryParams: Params | null) {
    await this._router.navigate(
      [],
      {
        relativeTo: activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: '',
      },
    );
  }

  public getVisitorDebuggerUrl(visitorIdOrVisitorDeviceId: string): string {
    return this._buildUrl([
      BackofficeRouteLocations.Dashboard,
      BackofficeRouteLocations.DashboardChildren.VisitorDebugger,
    ], {
      id: visitorIdOrVisitorDeviceId,
    });
  }

  public getDropshippingCartDebuggerUrl(dropshippingCartId: string) {
    return this._buildUrl([
      BackofficeRouteLocations.Dashboard,
      BackofficeRouteLocations.DashboardChildren.Dropshipping,
    ], {
      cart: dropshippingCartId,
    });
  }
}
