<div class="upload-container" [class.circle]="display === 'circle'">
  <app-file-display *ngIf="file" [file]="file"></app-file-display>

  <form #dropFileForm>
    <input
      id="file-upload-{{ name }}"
      class="file-upload"
      #imageUploadInput
      type="file"
      accept="image/x-png,image/jpeg"
      (change)="onFileInputChange(imageUploadInput)"
    />

    <label id="file-upload-label" [class.file-dragged-over]="fileDraggedOver" for="file-upload-{{ name }}">
      <div class="info p-2">Click here or drop file</div>
    </label>
  </form>

  <div *ngIf="showError" class="mt-2 text-danger text-center">File is too big</div>
</div>
