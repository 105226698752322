import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UserSession } from '../../../common/dto/user-session.dto';

export interface BackofficeAppStateModel {
  isInitialized: boolean;

  isUserLoggedIn: boolean;

  session: UserSession | undefined;
}

export class SetIsInitializedAction {
  static readonly type = 'Set Is initialized Action';
}

export class SetSessionAction {
  static readonly type = 'Set Session Action';

  constructor(public session: UserSession | undefined) {}
}

@State<BackofficeAppStateModel>({
  name: 'BackofficeAppState',
  defaults: {
    isInitialized: false,
    isUserLoggedIn: false,
    session: undefined,
  },
})
@Injectable()
export class BackofficeAppState {
  @Action(SetSessionAction)
  setSession(ctx: StateContext<BackofficeAppStateModel>, action: SetSessionAction) {
    ctx.patchState({
      isUserLoggedIn: action.session !== undefined,
      session: action.session,
    });
  }

  @Action(SetIsInitializedAction)
  setInitialized(ctx: StateContext<BackofficeAppStateModel>) {
    ctx.patchState({
      isInitialized: true,
    });
  }

  @Selector()
  static session(state: BackofficeAppStateModel) {
    return state.session;
  }
}
