import { Component, Input } from '@angular/core';
import { FileType } from '../../../../../../common/model/file-type.enum';
import { FileDTO } from '../../../../../../common/dto/file.dto';

@Component({
  selector: 'app-file-display',
  templateUrl: './file-display.component.html',
})
export class FileDisplayComponent {
  @Input()
  public file!: FileDTO;

  public FileTypes = FileType;
}
