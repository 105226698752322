import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { FileType } from '../../../../../../common/model/file-type.enum';
import { fromEvents } from '../../utils/observable.utils';
import { FileDTO, FileId, MAX_FILE_SIZE_IN_BYTES } from '../../../../../../common/dto/file.dto';

@UntilDestroy()
@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploaderComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  private _onChange: any;
  private _onTouched: any;

  constructor(private _changeDetector: ChangeDetectorRef) {}

  @ViewChild('dropFileForm', { static: true })
  public dropFileForm!: ElementRef<HTMLFormElement>;

  @ViewChild('imageUploadInput', { static: true })
  public fileUploadInput!: ElementRef<HTMLInputElement>;

  @Input()
  public name?: string;

  @Input()
  public display: 'normal' | 'circle' = 'normal';

  public fileDraggedOver: boolean = false;

  public file?: FileDTO;

  public showError = false;

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {
    fromEvents<Event>(this.dropFileForm.nativeElement, [
      'drag',
      'dragstart',
      'dragend',
      'dragover',
      'dragenter',
      'dragleave',
      'drop',
    ])
      .pipe(untilDestroyed(this))
      .subscribe((e: Event) => {
        e.stopPropagation();
        e.preventDefault();

        this._changeDetector.markForCheck();
      });

    fromEvents(this.dropFileForm.nativeElement, ['dragover', 'dragenter'])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fileDraggedOver = true;

        this._changeDetector.markForCheck();
      });

    fromEvents(this.dropFileForm.nativeElement, ['dragleave', 'dragend', 'drop'])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fileDraggedOver = false;

        this._changeDetector.markForCheck();
      });

    fromEvent(this.dropFileForm.nativeElement, 'drop')
      .pipe(untilDestroyed(this))
      .subscribe(async (e: any) => {
        this.fileUploadInput.nativeElement.files = e.dataTransfer.files;

        await this.onFileInputChange(this.fileUploadInput.nativeElement);
      });
  }

  public async onFileInputChange(fileUploadInput: HTMLInputElement) {
    this.showError = false;

    if (!fileUploadInput || !fileUploadInput?.files?.length) {
      return;
    }

    const file = fileUploadInput.files[0];

    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
      this.showError = true;

      this.file = undefined;
      this._emitChange();

      return;
    }

    try {
      const fileContent = await this._readFileAsync(file);

      this.file = {
        id: <FileId>'',
        url: <string>fileContent,
        name: file.name,
        type: FileType.Image,
        file: <any>file,
      };

      this._emitChange();
    } catch {
      this.showError = true;
    }

    this._changeDetector.markForCheck();
  }

  private _readFileAsync(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener('error', (e) => {
        reject(e);
      });

      reader.addEventListener('load', () => {
        resolve(reader.result);
      });

      reader.readAsDataURL(file);
    });
  }

  private _emitChange() {
    const emitValue = this.file;

    this._onTouched(emitValue);
    this._onChange(emitValue);
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {}

  public writeValue(file: FileDTO): void {
    this.file = file;

    this._changeDetector.markForCheck();
  }
}
