import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import {
  DropshippingCartFullDTO,
  DropshippingCartId,
  DropshippingSupplierCartDTO,
} from '../../../../../common/dto/dropshipping.dto';

@Injectable()
export class DropshippingService {
  constructor(private _http: CoolHttp) {}

  public async getDropshippingCartAsync(
    dropshippingCartId: DropshippingCartId,
  ): Promise<{
    cart: DropshippingCartFullDTO;

    supplierCarts: DropshippingSupplierCartDTO[];
  }> {
    return await this._http.getAsync(`api/backoffice/dropshipping-cart-debugger`, {
      params: {
        id: dropshippingCartId,
      },
    });
  }

  public async tryPayOutSuppliersAsync(cartId: DropshippingCartId) {
    await this._http.postAsync(`api/dropshipping/carts/${ cartId }/supplier-payout`);
  }

  public async removeAndRecreateCartAsync(dropshippingCartId: DropshippingCartId) {
    return await this._http.postAsync(`api/backoffice/dropshipping-cart-debugger/remove-and-recreate`, {
      dropshippingCartId: dropshippingCartId,
    });
  }
}
