import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { CoolLocalStorage } from '@angular-cool/storage';
import { WINDOW } from '../utils/injection-tokens';
import { DateUtils } from '../../../../../common/utils/date.utils';

export const CHUNK_LOAD_ERROR_MESSAGE_REGEX = /Loading chunk [\d]+ failed/;
export const CHUNK_RELOAD_TIME_STORAGE_KEY = 'last-chunk-reload';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    @Inject(WINDOW) private _window: Window,
    private _localStorage: CoolLocalStorage,
    private _logger: LoggerService,
  ) {}

  handleError(err: any): void {
    if (CHUNK_LOAD_ERROR_MESSAGE_REGEX.test(err.message)) {
      const lastReloadTime = this._localStorage.getItem(CHUNK_RELOAD_TIME_STORAGE_KEY);

      if (!lastReloadTime || DateUtils.getMinutesBetween(new Date(), new Date(lastReloadTime)) > 1) {
        this._localStorage.setItem(CHUNK_RELOAD_TIME_STORAGE_KEY, new Date().toISOString());

        this._window.location.reload();

        return;
      }
    }

    this._logger.error(err.originalError || err.error || err);
  }
}
