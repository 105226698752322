import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { BackofficeAppStateModel } from '../../../app.state';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
  areFeaturesEnabled,
  FeaturePermission,
} from '../../../../../../common/model/feature-permissions.enum';

export interface FeaturePermissionsRouteGuardData {
  requiredFeaturePermissions: FeaturePermission[];
}

@Injectable()
export class FeaturePermissionsRouteGuardLogic  {
  constructor(private _store: Store, private _router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._store
      .select<BackofficeAppStateModel>((state) => state.BackofficeAppState)
      .pipe(
        filter((state) => !!state?.isInitialized),
        take(1),
        map((applicationState) => {
          const routeData: FeaturePermissionsRouteGuardData = <FeaturePermissionsRouteGuardData>route.data;

          const requiredFeaturePermissions = routeData.requiredFeaturePermissions;

          if (!requiredFeaturePermissions) {
            return false;
          }

          if (!applicationState.session?.permissions) {
            return false;
          }

          const hasPermission = areFeaturesEnabled(requiredFeaturePermissions, applicationState.session.permissions);

          if (!hasPermission) {
            alert('Insufficient permission!');
          }

          return hasPermission;
        }),
      );
  }
}

export const FeaturePermissionsRouteGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  return inject(FeaturePermissionsRouteGuardLogic).canActivate(route);
};
