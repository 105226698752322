import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { PCacheable } from 'ts-cacheable';
import { MINUTE_IN_MILLISECONDS } from '../../../../../common/utils/date.utils';
import { PlacementDTO } from '../../../../../common/dto/placement.dto';

@Injectable()
export class PlacementsService {
  constructor(private _http: CoolHttp) {}

  @PCacheable({
    maxAge: 10 * MINUTE_IN_MILLISECONDS,
  })
  public async getAllPlacementsAsync(): Promise<PlacementDTO[]> {
    return await this._http.getAsync('api/placements/all')
  }
}
