import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AccountId } from '../../../../../../common/dto/account.dto';
import { BrandDTO } from '../../../../../../common/dto/brand.dto';
import { BrandsService } from '../../services/brands.service';

@Component({
  selector: 'app-brand-by-account-tag',
  templateUrl: './brand-by-account-tag.component.html',
  styleUrls: ['./brand-by-account-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandByAccountTagComponent implements OnInit {

  constructor(
    private _brandsService: BrandsService,
    private _changeDetector: ChangeDetectorRef,
  ) { }

  @Input()
  public accountId!: AccountId;

  @Input()
  public disableClick = false;

  public brand?: BrandDTO;

  public async ngOnInit() {
    const allBrands = await this._brandsService.getAllBrandsWithInactiveAsync();

    this.brand = allBrands.find(_ => _.accountId === this.accountId);

    this._changeDetector.markForCheck();
  }
}
