import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BackofficeAppStateModel } from '../../../app.state';
import { BackofficeRouteNavigationService } from '../route-navigation.service';

@Injectable()
export class LoggedInRouteGuardLogic {
  constructor(
    private _store: Store,
    private _routeNavigationService: BackofficeRouteNavigationService,
  ) {}

  public canActivate(): Observable<boolean> {
    return this._store
      .select<BackofficeAppStateModel>((state) => state.BackofficeAppState)
      .pipe(
        filter((state: BackofficeAppStateModel) => !!state?.isInitialized),
        take(1),
        map((applicationState: BackofficeAppStateModel) => {
          if (!applicationState.isUserLoggedIn) {
            this._routeNavigationService.goToLoginAsync(true);
          }

          return applicationState.isUserLoggedIn;
        }),
      );
  }
}

export const LoggedInRouteGuard: CanActivateFn = (): Observable<boolean> => {
  return inject(LoggedInRouteGuardLogic).canActivate();
};
