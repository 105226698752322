import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { BrandCategoryDTO, BrandCategoryId } from '../../../../../common/dto/brand-categories.dto';
import { BrandDTO, BrandFullDTO, BrandId, BrandInviteDTO } from '../../../../../common/dto/brand.dto';
import { AccountId } from '../../../../../common/dto/account.dto';
import { BrandPriorityDTO } from '../../../../../common/dto/brand-priority.dto';
import { ProductDTO } from '../../../../../common/dto/product.dto';
import { PCacheable, PCacheBuster } from 'ts-cacheable';
import { MINUTE_IN_MILLISECONDS } from '../../../../../common/utils/date.utils';
import { AlgorithmBrandSettingsDTO } from '../../../../../common/dto/algorithm.dto';
import { Subject } from 'rxjs';
import { PromotionDTO } from '../../../../../common/dto/promotions.dto';
import { PlacementDTO, PlacementId } from '../../../../../common/dto/placement.dto';

export const priorityCacheBuster$ = new Subject<void>();

@Injectable()
export class BrandsService {
  constructor(private _http: CoolHttp) {}

  public async getBrandCategoriesAsync() {
    return await this._http.getAsync(`api/brand-categories`);
  }

  public async upsertBrandCategoriesAsync(brandCategory: BrandCategoryDTO) {
    return await this._http.postAsync(`api/brand-categories`, brandCategory);
  }

  public async removeBrandCategoryAsync(brandCategoryId: BrandCategoryId) {
    return await this._http.deleteAsync(`api/brand-categories/${ brandCategoryId }`);
  }

  @PCacheable({
    maxAge: MINUTE_IN_MILLISECONDS,
  })
  public async getAllBrandsWithInactiveAsync(): Promise<BrandDTO[]> {
    return await this._http.getAsync(`api/brands`, {
      params: {
        inactive: '1',
      },
    });
  }

  public async getBrandsOfAccountAsync(accountId: AccountId): Promise<BrandFullDTO[]> {
    return await this._http.getAsync(`api/accounts/${ accountId }/brands`, {
      params: {
        preview: '1',
      },
    });
  }

  public async activateBrandAsync(brandId: BrandId) {
    return await this._http.postAsync(`api/brands/${ brandId }/activate`);
  }

  public async disableBrandAsync(brandId: BrandId) {
    return await this._http.postAsync(`api/brands/${ brandId }/disable`);
  }

  public async setBrandTrustedByAsync(brandId: BrandId, trustedBy: boolean) {
    return await this._http.postAsync(`api/brands/${ brandId }/trusted-by`, {
      trustedBy: trustedBy,
    });
  }

  @PCacheable({
    maxAge: MINUTE_IN_MILLISECONDS,
    cacheBusterObserver: priorityCacheBuster$,
  })
  public async getBrandPrioritiesAsync(accountId: AccountId): Promise<{
    now: Date,
    placement: PlacementDTO,
    updatedAt: Date,
    highValueBrandPriorities: BrandPriorityDTO[],
    lowValueBrandPriorities: BrandPriorityDTO[],
  }[]> {
    return await this._http.getAsync(`api/tracking/brand-priorities/${ accountId }`);
  }

  @PCacheBuster({
    cacheBusterNotifier: priorityCacheBuster$,
  })
  public async upsertAlgorithmSettingsForBrandAsync(brandId: BrandId, settings: AlgorithmBrandSettingsDTO) {
    return await this._http.postAsync(`api/tracking/brand-priorities/${ brandId }/settings`, settings);
  }

  public async getProductsOfBrandAsync(brandId: BrandId): Promise<ProductDTO[]> {
    return await this._http.getAsync(`api/brands/${ brandId }/products`, {
      params: {
        preview: '1',
      },
    });
  }

  @PCacheable({
    maxAge: MINUTE_IN_MILLISECONDS,
  })
  public async getBrandInvitesAsync(): Promise<BrandInviteDTO[]> {
    return await this._http.getAsync(`api/brand-invites`);
  }

  public async getPromotionsOfBrandAsync(brandId: BrandId): Promise<PromotionDTO[]> {
    return await this._http.getAsync(`api/brands/${ brandId }/promotions`, {
      params: {
        preview: '1',
      },
    });
  }

  public async refreshShopifyIntegrationAsync(placementId: PlacementId) {
    await this._http.postAsync(`api/placements/${ placementId }/integrations/shopify/refresh`);
  }
}
