import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import {
  FeaturePermissionOverrideRuleDTO,
  FeaturePermissionOverrideRuleId,
} from '../../../../../common/model/feature-permission-overrides.interface';

@Injectable()
export class FeatureOverridesService {
  constructor(
    private _http: CoolHttp,
  ) {}

  public async getFeaturePermissionOverrideRulesAsync(): Promise<FeaturePermissionOverrideRuleDTO[]> {
    return await this._http.getAsync('api/backoffice/feature-permission-override-rules');
  }

  public async upsertFeaturePermissionOverrideRulesAsync(rule: FeaturePermissionOverrideRuleDTO) {
    await this._http.postAsync('api/backoffice/feature-permission-override-rules', rule);
  }

  public async deleteFeaturePermissionOverrideRulesAsync(ruleId: FeaturePermissionOverrideRuleId) {
    await this._http.deleteAsync(`api/backoffice/feature-permission-override-rules/${ ruleId }`);
  }
}
