import { Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { AccountUserRole } from '../../../../../common/model/account-user-role.enum';
import { AccountDTO, AccountId } from '../../../../../common/dto/account.dto';
import { AccountUserFullDTO, AccountUserId } from '../../../../../common/dto/account-user.dto';
import { SubscriptionDTO, SubscriptionId } from '../../../../../common/dto/subscription.dto';
import {
  AccountUserInvitationDTO,
  AccountUserInvitationId,
} from '../../../../../common/dto/account-user-invitation.dto';
import { AccountDetailsDTO } from '../../../../../common/dto/account-details.dto';
import {
  AccountStatisticsDTO,
  AccountStatisticsMonthDifferenceDTO,
} from '../../../../../common/dto/account-statistics.dto';
import { PlacementComponentDTO, PlacementDTO } from '../../../../../common/dto/placement.dto';
import { AccountFeaturesDTO } from '../../../../../common/dto/account-features.dto';
import { SubscriptionType } from '../../../../../common/model/subscription-type.enum';
import { ImpressionPackageDTO } from '../../../../../common/dto/impression-packages.dto';

@Injectable()
export class AccountsService {
  constructor(private _http: CoolHttp) {}

  public async getAccountsAsync(fromId: AccountId, filter: string | undefined, limit: number = 1000): Promise<AccountDTO[]> {
    const params: any = {
      from: fromId,
      limit: limit,
    };

    if (filter) {
      params.filter = filter;
    }

    return await this._http.getAsync(`api/accounts`, {
      params: params,
    });
  }

  public async getAccountAsync(accountId: AccountId): Promise<AccountDTO> {
    return await this._http.getAsync(`api/accounts/${ accountId }`);
  }

  public async getAccountDetailsByIdAsync(accountId: AccountId): Promise<AccountDetailsDTO | undefined> {
    return await this._http.getAsync(`api/accounts/${ accountId }/details`);
  }

  public async getAccountStatisticsAsync(accountId: AccountId): Promise<AccountStatisticsDTO[]> {
    return await this._http.getAsync(`api/accounts/${ accountId }/statistics/months`);
  }

  public async getMonthAccountStatisticsAsync(accountId: AccountId, year: number, month: number): Promise<AccountStatisticsMonthDifferenceDTO | undefined> {
    return await this._http.getAsync(`api/accounts/${ accountId }/statistics/months/${ year }-${ month }`);
  }

  public async createAccountAsync(name: string): Promise<AccountDTO> {
    return await this._http.postAsync(`api/accounts`, {
      name: name,
    });
  }

  public async updateAccountNameAsync(accountId: AccountId, name: string) {
    return await this._http.postAsync(`api/accounts/${ accountId }/details/name`, {
      name: name,
    });
  }

  public async createSubscriptionAsync(accountId: AccountId, subscription: SubscriptionType, expiration: string | undefined) {
    await this._http.postAsync(`api/accounts/${ accountId }/subscriptions/custom`, {
      expiration: expiration,
      subscription: subscription,
    });
  }

  public async cancelSubscriptionAsync(accountId: AccountId, subscriptionId: SubscriptionId) {
    await this._http.deleteAsync(`api/accounts/${ accountId }/subscriptions/${ subscriptionId }`);
  }

  public async getSubscriptionsOfAccountAsync(accountId: AccountId): Promise<SubscriptionDTO[]> {
    return await this._http.getAsync(`api/accounts/${ accountId }/subscriptions`);
  }

  public async getUsersInAccountAsync(accountId: AccountId): Promise<AccountUserFullDTO[]> {
    return await this._http.getAsync(`api/accounts/${ accountId }/users`);
  }

  public async getUserInvitationsInAccountAsync(accountId: AccountId): Promise<AccountUserInvitationDTO[]> {
    return await this._http.getAsync(`api/accounts/${ accountId }/users/invitations`);
  }

  public async removeUserInvitationsInAccountAsync(accountId: AccountId, invitationId: AccountUserInvitationId) {
    return await this._http.deleteAsync(`api/accounts/${ accountId }/users/invitations/${ invitationId }`);
  }

  public async inviteUserToAccountAsync(accountId: AccountId, details: {
    role: AccountUserRole;
    email: string
  }): Promise<AccountUserInvitationDTO> {
    return await this._http.postAsync(`api/accounts/${ accountId }/users/invitations`, details);
  }

  public async addMeAsSupportUserToAccountAsync(accountId: AccountId) {
    return await this._http.postAsync(`api/accounts/${ accountId }/users/support`, {});
  }

  public async removeUserFromAccountAsync(accountId: AccountId, accountUserId: AccountUserId) {
    await this._http.deleteAsync(`api/accounts/${ accountId }/users/${ accountUserId }`);
  }

  public async getPlacementsOfAccountAsync(accountId: AccountId): Promise<PlacementDTO[]> {
    return await this._http.getAsync(`api/accounts/${ accountId }/placements`);
  }

  public async getPlacementsComponentsOfAccountAsync(accountId: AccountId): Promise<PlacementComponentDTO[]> {
    return await this._http.getAsync(`api/accounts/${ accountId }/placement-components`);
  }

  public async sendTestEmail(accountId: AccountId, type: 'monthly-email'): Promise<void> {
    return await this._http.postAsync(`api/backoffice/email-tester/${ type }`, {
      accountId: accountId,
    });
  }

  public async deleteAccountAsync(accountId: AccountId) {
    await this._http.deleteAsync(`api/accounts/${ accountId }`);
  }

  public async getAccountFeaturesAsync(accountId: AccountId): Promise<AccountFeaturesDTO> {
    return await this._http.getAsync(`api/accounts/${ accountId }/features`);
  }

  public async setInactiveBrandNetworkAccessForAccountAsync(accountId: AccountId, enabled: boolean): Promise<AccountFeaturesDTO> {
    return await this._http.postAsync(`api/accounts/${ accountId }/features/inactive-brand-network`, {
      enabled: enabled,
    });
  }

  public async getAccountImpressionPackagesAsync(accountId: AccountId): Promise<ImpressionPackageDTO[]> {
    return await this._http.getAsync(`api/accounts/${ accountId }/impression-packages`);
  }

  public async createAccountImpressionPackageAsync(accountId: AccountId, impressionCount: number) {
    await this._http.postAsync(`api/accounts/${ accountId }/impression-packages/complimentary`, {
      impressionCount: impressionCount,
    });
  }
}
