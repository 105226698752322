import { RouteLocationNode } from '../../../../../common/model/route-location-node';

export const BackofficeRouteLocations = {
  Login: new RouteLocationNode('login'),

  Dashboard: 'dashboard',
  DashboardChildren: {
    Accounts: 'accounts',
    AccountsChildren: {
      AccountList: '',

      AccountDetails: ':accountId',

      AccountDetailsChildren: {
        AccountContactDetails: 'contact',
        AccountFeatures: 'features',
        AccountPartners: 'partners',
        AccountBrands: 'brands',
        AccountSubscriptions: 'subscriptions',
        AccountImpressionPackages: 'impression-packages',
        AccountUsers: 'users',
        Emails: 'emails',
        Preview: 'preview',
        Advanced: 'advanced',
      },
    },

    Dropshipping: 'dropshipping',

    Monitoring: 'monitoring',
    MonitoringChildren: {
      WebpageList: '',
      ReportList: ':webpageId/reports',
      Report: ':webpageId/reports/:reportId',
    },

    BrandCategories: 'brand-categories',

    BrandPriorities: 'brand-priorities',

    BrandInvites: 'brand-invites',

    AudienceExports: 'audience-exports',

    PartnershipRequests: 'partnership-requests',

    VisitorDevices: 'visitor-devices',

    ServerFunctions: 'server-functions',

    FeatureOverrides: 'feature-overrides',

    VisitorDebugger: 'visitor-debugger',

    Reports: 'reports',

    NotFound: 'not-found',
  },
};
