import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BrandMinimalDTO } from '../../../../../../common/dto/brand.dto';
import { BackofficeRouteNavigationService } from '../../routing/route-navigation.service';

@Component({
  selector: 'app-brand-tag',
  templateUrl: './brand-tag.component.html',
  styleUrls: ['./brand-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandTagComponent implements OnInit {

  constructor(
    private _routeNavigationService: BackofficeRouteNavigationService,
  ) { }

  @Input()
  public brand!: BrandMinimalDTO;

  @Input()
  public disableClick = false;

  ngOnInit(): void {
  }

  public async onClickAsync() {
    if (this.disableClick) {
      return;
    }

    await this._routeNavigationService.goToAccountDetailsAsync(this.brand.accountId);
  }
}
