import { Inject, Injectable } from '@angular/core';
import { CoolHttp } from '@angular-cool/http';
import { Store } from '@ngxs/store';
import { CoolLocalStorage } from '@angular-cool/storage';
import { Environment } from '../../../environments/environment.interface';
import { UserSessionWithAppAccount } from '../../../../../common/dto/user-session.dto';
import { lastValueFrom } from 'rxjs';
import { AuthToken, AuthTokenPayload, OneTimeAuthToken } from '../../../../../common/dto/auth.dto';
import { ApplicationComponent } from '../../../../../common/dto/app.dto';
import { ENVIRONMENT, WINDOW } from '../utils/injection-tokens';
import { SetSessionAction } from '../../app.state';
import { jwtDecode } from 'jwt-decode';

export const API_TOKEN_STORAGE_KEY = 'ohana-token';

@Injectable()
export class AuthenticationService {
  constructor(
    private _store: Store,
    private _http: CoolHttp,
    private _localStorage: CoolLocalStorage,
    @Inject(ENVIRONMENT) private _environment: Environment,
    @Inject(WINDOW) private _window: Window,
  ) {}

  public async initializeAsync() {
    const savedApiToken = <AuthToken | undefined | null>this._localStorage.getItem(API_TOKEN_STORAGE_KEY);

    if (savedApiToken) {
      this._setApiToken(savedApiToken);
    }

    await this.refreshUserSessionAsync();
  }

  public async goToLoginAsync() {
    this._window.location.href = this._environment.loginUrl + `?app=${ ApplicationComponent.BackOffice }`;
  }

  public async goToRegistrationAsync() {
    this._window.location.href = this._environment.loginUrl + `/registration?app=${ ApplicationComponent.BackOffice }`;
  }

  public async createSessionFromOneTimeAuthTokenAsync(oneTimeAuthToken: OneTimeAuthToken): Promise<boolean> {
    let response: any = undefined;

    try {
      response = await this._http.postAsync<{ token: string }>('api/authentications/session/from-one-time-token', {
        token: oneTimeAuthToken,
        component: ApplicationComponent.BackOffice,
      });

      await this._setSessionFromTokenResponseAsync(response);

      return true;
    } catch {
      return false;
    }
  }

  public async refreshUserSessionAsync() {
    let response: any = undefined;

    try {
      response = await this._http.postAsync<{ token: string }>('api/authentications/session', {
        component: ApplicationComponent.BackOffice,
      });
    } catch {}

    await this._setSessionFromTokenResponseAsync(response);
  }

  public async logoutAsync() {
    await this._http.postAsync(`api/authentications/logout`);

    this._localStorage.removeItem(API_TOKEN_STORAGE_KEY);

    this._http.deregisterGlobalHeader('Authorization');

    await lastValueFrom(this._store.dispatch(new SetSessionAction(undefined)));

    this._window.location.href = this._environment.loginUrl + '/logout' + `?app=${ ApplicationComponent.BackOffice }`;
  }

  public async createAccountAsync() {
    await this._http.postAsync('api/app/user-setup');

    await this.refreshUserSessionAsync();
  }

  private async _setSessionFromTokenResponseAsync(response: { token: AuthToken }) {
    let session: UserSessionWithAppAccount | undefined = undefined;

    if (response?.token) {
      session = <UserSessionWithAppAccount>jwtDecode<AuthTokenPayload>(response.token)?.session;
    }

    this._setApiToken(response?.token);

    await lastValueFrom(this._store.dispatch(new SetSessionAction(session)));
  }

  private _setApiToken(token: AuthToken | undefined) {
    this._localStorage.setItem(API_TOKEN_STORAGE_KEY, token || '');

    if (token) {
      this._http.registerGlobalHeader({
        key: 'Authorization',
        value: `Bearer ${ token }`,
      });
    } else {
      this._http.deregisterGlobalHeader('Authorization');
    }
  }
}
