import { VisitorDeviceIgnoreRuleDTO, VisitorDeviceIgnoreRuleId } from '../../../../../common/dto/visitor.dto';
import { CoolHttp } from '@angular-cool/http';
import { Injectable } from '@angular/core';

@Injectable()
export class VisitorDevicesService {
  constructor(
    private _http: CoolHttp,
  ) {}

  public async getIgnoreRulesAsync(): Promise<VisitorDeviceIgnoreRuleDTO[]> {
    return await this._http.getAsync('api/visitor-device-ignore-rules');
  }

  public async upsertIgnoreRuleAsync(rule: VisitorDeviceIgnoreRuleDTO): Promise<void> {
    return await this._http.postAsync('api/visitor-device-ignore-rules', rule);
  }

  public async removeIgnoreRuleAsync(id: VisitorDeviceIgnoreRuleId): Promise<void> {
    return await this._http.deleteAsync(`api/visitor-device-ignore-rules/${id}`);
  }
}
